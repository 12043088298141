import React from 'react';
import * as styles from './ListItem.module.scss';
import { ComponentProps } from './ListItem.model';
import { VCButton } from '../../../../../globalComponents/VCButton';
import { VCImg } from '../../../../../globalComponents/VCImg';

// markup
export const ListItem = ({
  projectTitle = 'PROJECT_TITLE',
  type = 'Job',
  position = 'UX/UI Design',
  year = 2020,
  link = '/',
  images = [],
  description = 'DESC',
  figmaLink,
  gitLink,
  total,
  currentNumber,
}: ComponentProps) => {
  return (
    <a className={styles.itemWrapper} href={`${link}`}>
      <div className={styles.contentWrapper}>
        <div>
          <h3 className={styles.projectTitle}>{projectTitle}</h3>
          <p>{description}</p>
        </div>
        <p>
          <span>
            {currentNumber} of {total}
          </span>
        </p>
      </div>
      <ul>
        {figmaLink && (
          <li>
            <VCButton
              label="Go to the Figma file."
              icon={['fab', 'figma']}
              link={figmaLink}
            />
          </li>
        )}
        {gitLink && (
          <li>
            {' '}
            <VCButton
              label="Go to the Git repository."
              icon={['fab', 'github']}
              link={gitLink}
            />
          </li>
        )}
      </ul>
      <div className={styles.imgWrapper}>
        {images.map(function (item, i) {
          return (
            <VCImg
              key={i}
              webpSrc={item.webp}
              jpegSrc={item.jpg}
              alt={item.alt}
              className={!item.main ? styles.notMainImg : ''}
              loading={currentNumber > 2 ? 'lazy' : 'eager'}
            />
          );
        })}
      </div>
    </a>
  );
};
