import React, { useState } from 'react';
import { ListItem } from './ListItem/ListItem.component';
import * as styles from './PortfolioList.module.scss';
import { listItems } from '../../../../utils/data/projectList';

// markup
export const PortfolioList = () => {
  return (
    <div className={styles.mainWrapper}>
      <h2>The works</h2>
      <ul>
        {listItems.map(function (item, i) {
          return (
            <li key={i}>
              <ListItem
                currentNumber={i + 1}
                total={listItems.length}
                projectTitle={item.projectTitle}
                type={item.type}
                position={item.position}
                year={item.year}
                link={item.link}
                images={item.images}
                description={item.description}
                gitLink={item.gitLink}
                figmaLink={item.figmaLink}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
