import React, { useState, useEffect, useRef } from 'react';
import { VCFooter } from '../../globalComponents/VCFooter';
import { PortfolioList } from './Components/PortfolioList';
import * as styles from './Home.module.scss';

// markup
export const Home = () => {
  return (
    <main className={styles.mainWrapper}>
      <section className={styles.firstHalf}>
        <h4>Hello there , I’m</h4>
        <h1>Guillermo López</h1>
        <p>Product designer / Frontend developer</p>
      </section>
      <section>
        <PortfolioList />
      </section>
      <VCFooter />
    </main>
  );
};
