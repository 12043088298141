import React from 'react';
import { Home } from '../modules/Home';
import Helmet from 'react-helmet';
import '../utils/theme/globalTheme.scss';
//Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faGlasses,
  faEnvelopeSquare,
  faArrowRight,
  faArrowLeft,
  faBrush,
  faUser,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import { VCNavBar } from '../globalComponents/VCNavBar';

library.add(
  fab,
  faGlasses,
  faEnvelopeSquare,
  faArrowRight,
  faArrowLeft,
  faBrush,
  faUser,
  faBriefcase
);

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta
          name="description"
          content="This is the portafolio page of Guillermo López."
        ></meta>
      </Helmet>
      <VCNavBar configuration="home" />
      <main>
        <title>Guillermo Lopez</title>
        <Home />
      </main>
    </>
  );
};

export default IndexPage;
